const loadResource = (file, filename, mime) => {
  const a = document.createElement('a');
  a.style.cssText = 'display: none';
  document.body.appendChild(a);
  a.href = `data:text/csv;charset=utf-8,${encodeURI(file)}`;
  a.download = filename;
  a.target = '_blank';
  a.type = mime;
  a.click();
};

export default loadResource;
