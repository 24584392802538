import React, { useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { Accordion, Form, Input, Icon, Checkbox } from 'semantic-ui-react';
import { Dropdown, DropdownMulti } from '../../components';

function ClientForm(props) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeIndexRem, setActiveIndexRem] = useState(-1);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const handleClickRem = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndexRem === index ? -1 : index;
    setActiveIndexRem(newIndex);
  };

  const adapt = (Component) =>
    function ({ input, meta: { valid }, ...rest }) {
      return <Component {...input} {...rest} value={valid.toString()} />;
    };

  return (
    <FinalForm
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      validate={props.validate}
      render={({ handleSubmit }) => (
        <Form method="post" onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Field
              component={Form.Field}
              control={Input}
              id="clients.form.firstName"
              label="First Name"
              placeholder="First Name"
              name="firstName"
            />
            <Field
              component={Form.Field}
              control={Input}
              id="clients.form.lastName"
              label="Last Name"
              placeholder="Last Name"
              name="lastName"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Field
              component={Dropdown}
              id="clients.form.language"
              label="Language"
              name="language"
              options={[
                { text: 'French', value: 'fr' },
                { text: 'Dutch', value: 'nl' },
                { text: 'English', value: 'en' }
              ]}
            />
            <Field
              component={Dropdown}
              id="clients.form.courseType"
              label="Course Type"
              name="courseType"
              options={[
                { text: 'Mixte', value: 'mixte' },
                { text: 'Online', value: 'online' },
                { text: 'Midis', value: 'midis' },
                { text: 'Ladies', value: 'ladies' },
                { text: 'Kids', value: 'kids' }
              ]}
            />
            <Field
              component={Dropdown}
              id="clients.form.label"
              label="Label"
              name="label"
              options={[
                { text: 'Client', value: 'client' },
                { text: 'Team', value: 'team' },
                { text: 'Competitor', value: 'competitor' },
                { text: 'Worker', value: 'worker' }
              ]}
            />
            <Field
              component={Dropdown}
              id="clients.form.gender"
              label="Gender"
              name="gender"
              options={[
                { text: 'Male', value: 'male' },
                { text: 'Female', value: 'female' }
              ]}
            />
          </Form.Group>
          <br />
          <Form.Group widths="equal">
            <Field
              component={Form.Field}
              control={Input}
              id="clients.form.phone"
              label="Phone"
              placeholder="Phone"
              name="phone"
            />
            <Field
              component={Form.Field}
              control={Input}
              id="clients.form.email"
              label="Email"
              placeholder="Email"
              name="email"
            />
            <Field
              component={Form.Field}
              control={Input}
              id="clients.form.birthdate"
              label="Birthdate"
              placeholder="YYYY-MM-DD"
              name="birthdate"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Field
              component={Dropdown}
              id="clients.form.subcription"
              label="Subcription"
              name="subcription"
              options={[
                { text: 'Try', value: 'try' },
                { text: 'Monthly', value: 'monthly' },
                { text: 'Quarter', value: 'quarter' },
                { text: 'Yearly', value: 'yearly' }
              ]}
            />
            <Field
              component={Form.Field}
              control={Input}
              id="clients.form.startDate"
              label="Start date"
              placeholder="YYYY-MM-DD"
              name="startDate"
            />
            <Field
              component={Form.Field}
              control={Input}
              id="clients.form.endDate"
              label="End date"
              placeholder="YYYY-MM-DD"
              name="endDate"
            />
            <Field
              component={DropdownMulti}
              id="clients.form.courses"
              label="Courses"
              name="courses"
              options={[
                { text: 'Monday', key: 'Monday', value: 1 },
                { text: 'Tuesday', key: 'Tuesday', value: 2 },
                { text: 'Wednesday', key: 'Wednesday', value: 3 },
                { text: 'Thursday', key: 'Thursday', value: 4 },
                { text: 'Friday', key: 'Friday', value: 5 },
                { text: 'Saturday', key: 'Saturday', value: 6 },
                { text: 'Sunday', key: 'Sunday', value: 0 }
              ]}
            />
          </Form.Group>
          <br />
          <Form.Group widths="equal">
            <Field
              type="checkbox"
              toggle
              component={adapt(Checkbox)}
              id="clients.form.insurance"
              label="Insurance"
              name="payment.insurance"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Field
              toggle
              type="checkbox"
              component={adapt(Checkbox)}
              id="clients.form.contribution"
              label="Contribution"
              name="payment.contribution"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Field
              component={Form.Field}
              control={Input}
              type="number"
              id="clients.form.annualPayment"
              label="Annual payment"
              name="annualPayment"
            />
          </Form.Group>
          <br />
          <Form.Group widths="equal">
            <Field
              toggle
              type="checkbox"
              component={adapt(Checkbox)}
              id="clients.form.active"
              label="Active"
              name="active"
            />
          </Form.Group>
          <br />
          <br />
          <Accordion>
            <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick}>
              <Icon name="dropdown" />
              Change the address ?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Form.Group widths="equal">
                <Field
                  component={Form.Field}
                  control={Input}
                  id="clients.form.street"
                  label="Street"
                  placeholder="Street"
                  name="address.street"
                />
                <Field
                  component={Form.Field}
                  control={Input}
                  id="clients.form.city"
                  label="City"
                  placeholder="City"
                  name="address.city"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Field
                  component={Form.Field}
                  control={Input}
                  id="clients.form.zip"
                  label="Zip"
                  placeholder="Zip"
                  name="address.zip"
                />
                <Field
                  component={Form.Field}
                  control={Input}
                  id="clients.form.number"
                  label="Number"
                  placeholder="Number"
                  name="address.number"
                />
                <Field
                  component={Form.Field}
                  control={Input}
                  id="clients.form.country"
                  label="Country"
                  placeholder="Country"
                  name="address.country"
                />
              </Form.Group>
            </Accordion.Content>
          </Accordion>
          <br />
          <br />
          <Accordion>
            <Accordion.Title active={activeIndexRem === 0} index={0} onClick={handleClickRem}>
              <Icon name="dropdown" />
              Change the global remark ?
            </Accordion.Title>
            <Accordion.Content active={activeIndexRem === 0}>
              <Form.Group widths="equal">
                <Field
                  component={Form.Field}
                  control={Input}
                  id="clients.form.globalRemark"
                  label="Global Remark"
                  placeholder="Global Remark"
                  name="globalRemark"
                />
                <Field
                  component={Form.Field}
                  control={Input}
                  id="clients.form.globalRemark2"
                  label="Global Remark 2"
                  placeholder="Global Remark 2"
                  name="globalRemark2"
                />
              </Form.Group>
            </Accordion.Content>
          </Accordion>
          <br />
          <br />
          <Form.Button color="green">{props.actionLabel}</Form.Button>
        </Form>
      )}
    />
  );
}

export default ClientForm;
