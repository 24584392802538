import React from 'react';
import { Segment, Icon } from 'semantic-ui-react';

export default function PaymentView({ client }) {
  return (
    <Segment>
      <h1>Payment</h1>
      Insurance{' '}
      {client.payment.insurance ? (
        <Icon color="green" name="check" />
      ) : (
        <Icon color="red" name="cancel" />
      )}
      <br />
      <br />
      Contribution{' '}
      {client.payment.contribution ? (
        <Icon color="green" name="check" />
      ) : (
        <Icon color="red" name="cancel" />
      )}
    </Segment>
  );
}
