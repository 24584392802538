import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

function SaveButton(props) {
  const [saved] = useState(false);

  return saved ? (
    <Button disabled color="green">
      <Icon name="check" />
      <FormattedMessage id="saved" defaultMessage="Saved" />
    </Button>
  ) : (
    <Button disabled={props.disabled} type="submit" loading={props.saving} color="green">
      <FormattedMessage id="save" defaultMessage="Save" />
    </Button>
  );
}

export default SaveButton;
