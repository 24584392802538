import styled from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.bodyColor};
  display: flex;
  justify-content: center;
  padding: 15%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const FormTitle = styled.h3`
  color: ${(props) => props.theme.textColor};
  font-family: ${(props) => props.theme.fontFamily}, sans-serif;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`;

export const Submit = styled.button.attrs({ type: 'submit' })`
  border-style: none;
  background: ${(props) => props.theme.primaryColor};
  border: solid ${(props) => props.theme.primaryColor} 2px;
  margin-top: 20px;
  color: ${(props) => props.theme.bodyColor};
  font-family: ${(props) => props.theme.fontFamily}, sans-serif;
  font-size: 14px;
  font-weight: bold;
  height: 45px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;
