import React, { PureComponent } from 'react';
import { Button, Icon } from 'semantic-ui-react';

export default class EditButton extends PureComponent {
  render() {
    return (
      <Button icon onClick={this.props.onClick}>
        <Icon name="edit" />
      </Button>
    );
  }
}
