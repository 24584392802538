import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';

import { PrivateRoute } from '../components';
import Login from './login/Login';
import theme from '../theme';

import ClientsList from './clients/clientsList';
import ReportsView from './reports/reportsView';
import ClientEdit from './clients/clientEdit';
import ChangelogsList from './changeLogs/changelogsList';
import ClientView from './clients/clientView';

function App() {
  return (
    <IntlProvider locale="en">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <ClientsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/clients"
              element={
                <PrivateRoute>
                  <ClientsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/clients/:clientId"
              element={
                <PrivateRoute>
                  <ClientEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/clients/:clientId/view"
              element={
                <PrivateRoute>
                  <ClientView />
                </PrivateRoute>
              }
            />
            <Route
              path="/reports"
              element={
                <PrivateRoute>
                  <ReportsView />
                </PrivateRoute>
              }
            />
            <Route
              path="/reports/:category"
              element={
                <PrivateRoute>
                  <ReportsView />
                </PrivateRoute>
              }
            />
            <Route
              path="/changeLogs"
              element={
                <PrivateRoute>
                  <ChangelogsList />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
