import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import {
  Container,
  MenuClientView,
  StatusView,
  PaymentView,
  PaymentsView,
  AddressView,
  GeneralView
} from '../../components';
import { getClient } from '../../services/clientsService';
import { addPayment } from '../../services';
import StatusBar from '../statusBar/StatusBar';

export const Info = styled.div`
  > span {
    font-weight: bold;
  }
  padding: 2px;
`;

function ClientView() {
  const { clientId } = useParams();
  const [client, setClient] = useState({});
  const [view, setView] = useState('general');

  const doIHaveCourseToday = () => {
    const today = moment().day();
    if (client && client.courses) {
      return client.courses.some((c) => c === today);
    }
    return false;
  };

  const showError = () => {
    if (client.payment && !client.payment.contribution) {
      return (
        <Message
          negative
          icon="warning sign"
          header="You are not eligible for a course"
          content=" The client has not paid his contribution."
        />
      );
    }
    if (client.payment && !client.payment.insurance) {
      return (
        <Message
          negative
          icon="warning sign"
          header="You are not eligible for a course"
          content="The client has not paid his insurance."
        />
      );
    }
    if (!doIHaveCourseToday()) {
      return (
        <Message
          negative
          icon="warning sign"
          header="You are not eligible for a course"
          content="The client has not choosen today as a course day."
        />
      );
    }
    return (
      <Message
        positive
        icon="check circle outline"
        header="You are eligible for a course"
        content="The client has everything in order."
      />
    );
  };

  const showGlobalRemark = () => (
    <Message info icon="envelope outline" header="Global Remark" content={client.globalRemark} />
  );

  const showGlobalRemark2 = () => (
    <Message info icon="envelope outline" header="Global Remark 2" content={client.globalRemark2} />
  );

  const submitPayment = (payment) => {
    addPayment(clientId, payment)
      .then(() => {
        getClient(clientId).then((c) => setClient(c));
      })
      .catch(() => {});
  };

  const showView = () => {
    if (view === 'status') {
      return (
        <StatusView
          showError={showError}
          showGlobalRemark={showGlobalRemark}
          showGlobalRemark2={showGlobalRemark2}
        />
      );
    }
    if (view === 'payments') {
      return <PaymentsView client={client} submitPayment={submitPayment} />;
    }
    if (view === 'subscription') {
      return <PaymentView client={client} />;
    }
    if (view === 'address') {
      return <AddressView client={client} />;
    }
    return <GeneralView client={client} />;
  };

  useEffect(() => {
    getClient(clientId).then(setClient);
  }, [clientId]);

  return (
    <Container className="page">
      <StatusBar />
      <MenuClientView setView={setView} />
      {showView()}
    </Container>
  );
}

export default ClientView;
