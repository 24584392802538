import React, { PureComponent } from 'react';
import { Button } from 'semantic-ui-react';

export default class ExportButton extends PureComponent {
  render() {
    return (
      <Button
        className="exportButton"
        color="red"
        onClick={this.props.onClick}
        icon="download"
        content="Export"
      />
    );
  }
}
