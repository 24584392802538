import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  > .addButton {
    float: right;
  }
  &.small {
    display: flex;
    flex-direction: column;
  }
  &.page {
    margin: 50px;
    display: flex;
    flex-direction: column;
  }
  &.client {
    margin: 50px;
    width: 100%;
    display: flex;
  }
  &.full {
    height: 100%;
  }
`;

export default Container;
