import React, { useCallback, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Segment } from 'semantic-ui-react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  Container,
  Title,
  Table,
  SearchButton,
  ComposeSearchForm,
  ClientButton
} from '../../components';
import { getAllChangeLogs } from '../../services';
import { getParams } from '../../helpers/request';

import ChangeLogsSearchForm from './changeLogsSearch.form';
import StatusBar from '../statusBar/StatusBar';

const model = (handleViewClient) => ({
  date: {
    title: 'Date',
    format: (value, rowData) => <div>{rowData.date}</div>
  },
  client: {
    title: 'Client',
    format: (value, rowData) => (
      <ClientButton
        id={`${rowData.client?.firstName} ${rowData.client?.lastName}`}
        onClick={() => handleViewClient(rowData.client?.id)}
      />
    )
  },
  change: {
    title: 'Change',
    format: (value, rowData) => <div>{rowData.change}</div>
  }
});

function Changelogs() {
  const history = useNavigate();
  const location = useLocation();
  const [pagination, setPagination] = useState({
    page: getParams(location).page || 1,
    pages: 1
  });
  const [sorting] = useState({});
  const [changeLogs, setChangeLogs] = useState([]);
  const [showSearchForm, setShowSearchForm] = useState(false);

  const handleViewClient = (id) => {
    history(`/clients/${id}/view`);
  };

  const findChangeLogs = useCallback(
    () =>
      getAllChangeLogs(location.search).then((data) => {
        setPagination({ page: data.page, pages: data.pages });
        setChangeLogs(data.docs);
      }),
    [location]
  );

  useEffect(() => {
    findChangeLogs();
  }, [location, findChangeLogs]);

  const handlePageChange = (e, elm) => {
    const page = elm.activePage;
    history({ pathname: '/changeLogs', search: `page=${page}` });
    findChangeLogs();
  };

  const handleSearch = () => {
    setShowSearchForm(!showSearchForm);
  };

  return (
    <Container className="page">
      <StatusBar />
      <div>
        <Title id="title.changeLogs" defaultMessage="Change logs" />
        <SearchButton onClick={handleSearch} open={showSearchForm} />
      </div>
      {showSearchForm && (
        <Segment secondary>
          <ComposeSearchForm
            component={ChangeLogsSearchForm}
            location={location}
            history={history}
            actionLabel={<FormattedMessage id="search" defaultMessage="Search" />}
          />
        </Segment>
      )}
      <Table
        model={model(handleViewClient)}
        docs={changeLogs}
        pagination={pagination}
        handlePageChange={handlePageChange}
        location={location}
        history={history}
        sorting={sorting}
      />
    </Container>
  );
}

export default Changelogs;
