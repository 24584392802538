import React, { PureComponent } from 'react';
import { Button } from 'semantic-ui-react';

export default class SearchButton extends PureComponent {
  render() {
    return (
      <Button
        className="searchButton"
        color="blue"
        onClick={this.props.onClick}
        icon={this.props.open ? 'search minus' : 'search plus'}
        content="Search"
      />
    );
  }
}
