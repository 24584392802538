import { request, handleResponse } from '../helpers/request';

function logout(history) {
  localStorage.removeItem('token');
  history('/');
}

function login(username, password) {
  const body = `username=${username}&password=${password}`;
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

  return request('auth', 'POST', body, headers)
    .then(handleResponse)
    .then(({ token }) => {
      localStorage.setItem('token', token);
      return token;
    });
}

export { login, logout };
