import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Menu, Statistic, Segment } from 'semantic-ui-react';
import { Pie, Bar } from 'react-chartjs-2';

import { getClientsStats, getCoursesStats } from '../../services';
import { Container, Stats, Charts } from './reportsView.style';
import SearchForm from './search.form';
import StatusBar from '../statusBar/StatusBar';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function ReportsView() {
  const history = useNavigate();
  const { category } = useParams();
  const [clients, setClients] = useState({});
  const [courses, setCourses] = useState({});
  const [activeItem, setActiveItem] = useState(category || 'general');

  const requestCoursesStats = async () => {
    const cs = await getCoursesStats();
    setCourses(cs);
  };

  const requestClientsStats = async () => {
    const kids = await getClientsStats('courseType=kids');
    const mixte = await getClientsStats('courseType=mixte');
    const midis = await getClientsStats('courseType=midis');
    const online = await getClientsStats('courseType=online');
    const ladies = await getClientsStats('courseType=ladies');
    const clientsByCourses = {
      kids,
      mixte,
      midis,
      online,
      ladies,
      total: kids + mixte + midis + online + ladies
    };
    setClients(clientsByCourses);
  };

  useEffect(() => {
    requestCoursesStats();
    requestClientsStats();
  }, []);

  const mapByCoursesData = () => ({
    labels: ['Mixte', 'Ladies', 'Kids', 'Midis', 'Online'],
    datasets: [
      {
        data: [clients.mixte, clients.ladies, clients.kids, clients.midis, clients.online],
        backgroundColor: ['#FF0000', '#e03997', '#fbbd08', '#800080', '#008000']
      }
    ]
  });

  const mapDayData = () => {
    const datasets = [
      {
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        label: 'Ladies',
        borderWidth: 1,
        borderColor: 'rgba(255, 99, 132, 1)',
        data: courses && courses.ladies
      },
      {
        backgroundColor: 'rgba(217, 30, 24, 0.2)',
        label: 'Mixte',
        borderWidth: 1,
        borderColor: 'rgba(217, 30, 24, 1)',
        data: courses && courses.mixte
      },
      {
        label: 'Kids',
        backgroundColor: 'rgba(247, 202, 24, 0.21)',
        borderWidth: 1,
        borderColor: 'rgba(247, 202, 24, 1)',
        data: courses && courses.kids
      },
      {
        label: 'Midis',
        backgroundColor: 'rgba(128, 0, 128, 0.21)',
        borderWidth: 1,
        borderColor: 'rgba(128, 0, 128, 1)',
        data: courses && courses.midis
      },
      {
        label: 'Online',
        backgroundColor: 'rgba(0, 128, 0, 0.21)',
        borderWidth: 1,
        borderColor: 'rgba(0, 128, 0, 1)',
        data: courses && courses.online
      }
    ];
    return {
      labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      datasets
    };
  };

  const handleItemClick = (e, v) => {
    setActiveItem(v.name);
    history(`/reports/${v.name}`);
  };

  return (
    <Container>
      <StatusBar style={{ width: '100%' }} />
      <Stats>
        <Statistic.Group widths="three">
          <Statistic />
          <Statistic>
            <Statistic.Value>{clients.total || 0}</Statistic.Value>
            <Statistic.Label>Clients</Statistic.Label>
          </Statistic>
          <Statistic />
        </Statistic.Group>
      </Stats>
      <Menu pointing secondary>
        <Menu.Item name="general" active={activeItem === 'general'} onClick={handleItemClick} />
        <Menu.Item name="search" active={activeItem === 'search'} onClick={handleItemClick} />
        <Menu.Item name="courses" active={activeItem === 'courses'} onClick={handleItemClick} />
      </Menu>
      <Charts>
        <Segment>
          {activeItem === 'courses' && <Pie data={mapByCoursesData()} />}
          {activeItem === 'general' && <Bar data={mapDayData()} />}
          {activeItem === 'search' && (
            <SearchForm
              actionLabel="Search"
              onSubmit={async (s) => {
                const params = Object.entries(s)
                  .map(([key, val]) => `${key}=${val}`)
                  .join('&');
                const total = await getClientsStats(params);
                setClients({ total });
              }}
            />
          )}
        </Segment>
      </Charts>
    </Container>
  );
}

export default ReportsView;
