import React from 'react';
import { Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Input } from '../../components';
import { Container, Form, FormTitle, Submit } from './Login.style';

function LoginForm({ onSubmit, onChange, username, password, error }) {
  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <FormTitle>
          <FormattedMessage id="loginTitle" defaultMessage="Welcome to N.B.S" />
        </FormTitle>
        <Input
          padding="0px 0px 50px 0px"
          margin="0px 0px 20px 0px"
          id="username"
          label="Username"
          labelId="username"
          input={{ name: 'username', onChange, value: username }}
        />
        <br />
        <Input
          margin="0px 0px 20px 0px"
          padding="0px 0px 50px 0px"
          id="password"
          label="Password"
          labelId="password"
          name="password"
          type="password"
          input={{ name: 'password', onChange, value: password }}
        />
        <Submit>
          <FormattedMessage id="login.signIn" defaultMessage="Sign in" />
        </Submit>
        {error && (
          <Message negative>
            <Message.Header>Error</Message.Header>
            <p>{error}</p>
          </Message>
        )}
      </Form>
    </Container>
  );
}

export default LoginForm;
