import React from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { Form, Input } from 'semantic-ui-react';

function ClientSearchForm(props) {
  return (
    <FinalForm
      onSubmit={props.onSubmit}
      validate={props.validate}
      render={({ handleSubmit }) => (
        <Form method="post" onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Field
              component={Form.Field}
              control={Input}
              id="logs.client"
              label="Client"
              placeholder="Client"
              name="client"
            />
            <Field
              component={Form.Field}
              control={Input}
              id="logs.date"
              label="Date"
              placeholder="DD/MM/YYY"
              name="date"
            />
          </Form.Group>
          <Form.Button color="green">{props.actionLabel}</Form.Button>
        </Form>
      )}
    />
  );
}

export default ClientSearchForm;
