import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Container, PaymentsView, Title } from '../../components';
import { getClient, updateClient } from '../../services/clientsService';
import ClientForm from './client.form';
import { addPayment, removePayment } from '../../services';
import StatusBar from '../statusBar/StatusBar';

function ClientEdit(props) {
  const { clientId } = useParams();
  const history = useNavigate();
  const [client, setClient] = useState(null);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    getClient(clientId).then(setClient);
  }, [clientId]);

  const handleSubmit = (payload) => {
    console.log(payload.payments);
    console.log(client.payments);
    const { id } = payload;
    updateClient(id, payload).then((c) => {
      setClient(c);
      setEditable(false);
      history(`/clients/${id}`);
    });
  };

  const submitPayment = (payment) => {
    addPayment(clientId, payment)
      .then(() => {
        getClient(clientId).then((c) => {
          setClient(c);
          setEditable(false);
        });
      })
      .catch(() => {});
  };

  const deletePayment = (paymentId) => {
    removePayment(clientId, paymentId)
      .then(() => {
        getClient(clientId).then((c) => {
          setClient(c);
          setEditable(false);
        });
      })
      .catch(() => {});
  };

  return (
    <Container className="page">
      <StatusBar />
      <Title id="client.edit.title" defaultMessage="Client edit" />
      <ClientForm
        actionLabel={<FormattedMessage id="edit" defaultMessage="Edit" />}
        error={props.error}
        initialValues={client}
        onSubmit={handleSubmit}
        saving={props.saving}
        removeEditable={() => {
          setEditable(false);
        }}
        toggleEditable={() => {
          setEditable(!editable);
        }}
        editable={editable}
      />
      <hr />
      {client && (
        <PaymentsView client={client} submitPayment={submitPayment} deletePayment={deletePayment} />
      )}
    </Container>
  );
}

export default ClientEdit;
