import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon, Pagination, Table } from 'semantic-ui-react';
import get from 'lodash/get';
import styled from 'styled-components';

const TableRow = styled(Table.Row)`
  cursor: pointer;
`;

function TableComponent(props) {
  const search = new URLSearchParams(props.location.search);
  const history = useNavigate();

  const toggleSort = (name, disableSort) => () => {
    if (disableSort) {
      return;
    }

    search.set('page', props.pagination.page);
    search.set('sort', name);
    search.set(
      'sortDirection',
      search.get('sort') === name &&
        (!search.has('sortDirection') || search.get('sortDirection') === 'asc')
        ? 'desc'
        : 'asc'
    );

    history({ ...props.location, search: search.toString() });
  };

  return (
    <Table basic="very" striped>
      <Table.Header>
        <Table.Row>
          {Object.keys(props.model).map((column) => (
            <Table.HeaderCell
              key={column}
              onClick={toggleSort(column, props.model[column].disableSort)}>
              {props.model[column].title}

              {search.get('sort') === column && (
                <Icon
                  size="small"
                  name={search.get('sortDirection') === 'asc' ? 'arrow up' : 'arrow down'}
                />
              )}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.docs.map((row, idx) => (
          <TableRow key={idx} onClick={() => props.onRowClick && props.onRowClick(row)}>
            {Object.keys(props.model).map((column) => (
              <Table.Cell key={`${idx}.${column}`} width={props.model[column].width}>
                {props.model[column].format
                  ? props.model[column].format(get(row, column), row)
                  : get(row, column)}
              </Table.Cell>
            ))}
          </TableRow>
        ))}
      </Table.Body>
      {props.pagination && (
        <Table.Footer>
          <Table.Row textAlign="center">
            <Table.HeaderCell colSpan={Object.keys(props.model).length}>
              <Pagination
                ellipsisItem={null}
                defaultActivePage={props.pagination.page}
                onPageChange={(e, elm) => {
                  search.set('page', elm.activePage);
                  props.handlePageChange(search);
                }}
                totalPages={props.pagination.pages}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  );
}

export default TableComponent;
