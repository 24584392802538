import React from 'react';
import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

export const Info = styled.div`
  > span {
    font-weight: bold;
  }
  padding: 2px;
`;

export default function AddressView({ client }) {
  return (
    <Segment>
      <h1>Address</h1>
      {client.address ? (
        <div>
          <Info>
            <span>Street :</span>
            {client.address.street}
          </Info>
          <Info>
            <span>Number :</span>
            {client.address.number}
          </Info>
          <Info>
            <span>City :</span>
            {client.address.city}
          </Info>
          <Info>
            <span>Zip :</span>
            {client.address.zip}
          </Info>
          <Info>
            <span>Country :</span>
            {client.address.country}
          </Info>
        </div>
      ) : (
        <p>No address available</p>
      )}
    </Segment>
  );
}
