import React, { useCallback, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Segment, Label, Icon } from 'semantic-ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

import {
  Container,
  Title,
  Table,
  DeleteButton,
  EditButton,
  ViewButton,
  CreateButton,
  SearchButton,
  ExportButton,
  ComposeSearchForm
} from '../../components';
import { getAllClients, deleteClient, addClient, exportData } from '../../services';
import { getParams } from '../../helpers/request';
import loadResource from '../../helpers/download';

import StatusBar from '../statusBar/StatusBar';
import ClientForm from './client.form';
import ClientSearchForm from './clientSearch.form';

const model = (handleDelete, handleEdit, handleView) => ({
  id: {
    title: 'Client id',
    format: (value, rowData) => (
      <Label color="teal" horizontal>
        {rowData.id}
      </Label>
    ),
    width: 1
  },
  name: {
    title: 'Name',
    format: (value, rowData) => (
      <div>{`${_.startCase(rowData.firstName)} ${_.startCase(rowData.lastName)}`}</div>
    )
  },
  label: {
    title: 'Label',
    format: (value, rowData) => {
      if (rowData.label === 'client') {
        return (
          <Label as="a" color="purple" horizontal>
            Client
          </Label>
        );
      }
      if (rowData.label === 'worker') {
        return (
          <Label as="a" color="red" horizontal>
            Worker
          </Label>
        );
      }
      if (rowData.label === 'team') {
        return (
          <Label as="a" color="orange" horizontal>
            Team
          </Label>
        );
      }
      return (
        <Label as="a" color="teal" horizontal>
          Competitor
        </Label>
      );
    }
  },
  courseType: {
    title: 'Course',
    format: (value, rowData) => {
      if (rowData.courseType === 'mixte') {
        return (
          <Label as="a" color="red" horizontal>
            Mixte
          </Label>
        );
      }
      if (rowData.courseType === 'ladies') {
        return (
          <Label as="a" color="pink" horizontal>
            Ladies
          </Label>
        );
      }
      if (rowData.courseType === 'midis') {
        return (
          <Label as="a" color="purple" horizontal>
            Midis
          </Label>
        );
      }
      if (rowData.courseType === 'online') {
        return (
          <Label as="a" color="green" horizontal>
            Online
          </Label>
        );
      }
      return (
        <Label as="a" color="yellow" horizontal>
          Kids
        </Label>
      );
    }
  },
  order: {
    title: 'In order',
    format: (value, rowData) => {
      const inOrderPayment = rowData.payment.insurance && rowData.payment.contribution;
      const inOrderActive = rowData.active && moment(rowData.endDate).diff(moment()) > 0;
      if (inOrderActive && inOrderPayment) {
        return <Icon color="green" name="check" />;
      }
      return <Icon color="red" name="cancel" />;
    }
  },
  view: {
    width: 1,
    title: <FormattedMessage id="clients.view" defaultMessage="View" />,
    format: (value, rowData) => (
      <ViewButton id={rowData.id} onClick={() => handleView(rowData.id)} />
    )
  },
  edit: {
    width: 1,
    title: <FormattedMessage id="clients.edit" defaultMessage="Edit" />,
    format: (value, rowData) => (
      <EditButton id={rowData.id} onClick={() => handleEdit(rowData.id)} />
    )
  },
  delete: {
    title: <FormattedMessage id="clients.delete" defaultMessage="Delete" />,
    format: (value, rowData) => (
      <DeleteButton
        id={rowData.id}
        onClick={handleDelete}
        modalTitle={
          <FormattedMessage
            id="deleteClient"
            defaultMessage="Are you sure you want to delete this client {clientId}"
            values={{
              clientId: rowData.id
            }}
          />
        }
      />
    ),
    width: 1
  }
});

function Clients() {
  const history = useNavigate();
  const location = useLocation();
  const [pagination, setPagination] = useState({
    page: getParams(location).page || 1,
    pages: 1
  });
  const [sorting] = useState({});
  const [clients, setClients] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showSearchForm, setShowSearchForm] = useState(false);

  const findClients = useCallback(
    () =>
      getAllClients(location.search).then((data) => {
        setPagination({ page: data.page, pages: data.pages });
        setClients(data.docs);
      }),
    [location]
  );

  useEffect(() => {
    findClients();
  }, [location, findClients]);

  const handlePageChange = (search) => {
    history({ pathname: '/clients', search: search.toString() });
    findClients();
  };

  const handleDelete = (id) => {
    deleteClient(id).then(() => findClients());
  };

  const handleEdit = (id) => {
    history(`/clients/${id}`);
  };

  const handleView = (id) => {
    history(`/clients/${id}/view`);
  };

  const handleCreate = () => {
    setShowCreateForm(!showCreateForm);
  };

  const handleSearch = () => {
    setShowSearchForm(!showSearchForm);
  };

  const handleExport = () => {
    const filename = `clients-${moment().format()}.csv`;
    const mimeType = 'text/csv';
    return exportData().then((response) => loadResource(response, filename, mimeType));
  };

  const createClient = (client) => {
    addClient(client)
      .then(() => {
        setShowCreateForm(!showCreateForm);
        return findClients();
      })
      .catch(() => {});
  };

  return (
    <Container className="page">
      <StatusBar />
      <div>
        <Title id="title.clients" defaultMessage="Clients" />
        <CreateButton onClick={handleCreate} open={showCreateForm} />
        <SearchButton onClick={handleSearch} open={showSearchForm} />
        <ExportButton onClick={handleExport} />
      </div>
      {showCreateForm && (
        <Segment secondary>
          <ClientForm
            actionLabel={<FormattedMessage id="create" defaultMessage="Create" />}
            onSubmit={createClient}
          />
        </Segment>
      )}
      {showSearchForm && (
        <Segment secondary>
          <ComposeSearchForm
            component={ClientSearchForm}
            location={location}
            history={history}
            actionLabel={<FormattedMessage id="search" defaultMessage="Search" />}
          />
        </Segment>
      )}
      <Table
        model={model(handleDelete, handleEdit, handleView)}
        docs={clients}
        pagination={pagination}
        handlePageChange={handlePageChange}
        location={location}
        history={history}
        sorting={sorting}
      />
    </Container>
  );
}

export default Clients;
