import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { login } from '../../services';
import LoginForm from './Login.form';
import { isAuthenticated } from '../../helpers/auth-header';

function Login(props) {
  const history = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isAuthenticated()) {
      history('/clients');
    }
  }, [history]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsername(value);
    } else {
      setPassword(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setLoading(true);
    login(username, password)
      .then(() => {
        history('/clients');
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  return (
    <LoginForm
      onChange={handleChange}
      onSubmit={handleSubmit}
      loggingIn={props.loggingIn}
      submitted={submitted}
      loading={loading}
      error={error}
      username={username}
      password={password}
    />
  );
}

export default Login;
