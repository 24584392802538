import { request, handleResponse } from '../helpers/request';
import { authHeader } from '../helpers/auth-header';

function exportData() {
  return request('reports/export', 'GET', null, authHeader()).then((res) => res.text());
}

function getClientsStats(params) {
  return request(`reports/clients?${params}`, 'GET', null, authHeader()).then(handleResponse);
}

function getCoursesStats(params) {
  return request(`reports/courses?${params}`, 'GET', null, authHeader()).then(handleResponse);
}

export { getCoursesStats, getClientsStats, exportData };
