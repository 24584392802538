import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react';

function MenuClientView(props) {
  const [activeItem, setActiveItem] = useState('general');
  const { setView } = props;

  const handleItemClick = (e, v) => {
    setActiveItem(v.name);
    setView(v.name);
  };

  return (
    <Menu pointing secondary>
      <Menu.Item name="general" active={activeItem === 'general'} onClick={handleItemClick} />
      <Menu.Item name="address" active={activeItem === 'address'} onClick={handleItemClick} />
      <Menu.Item name="status" active={activeItem === 'status'} onClick={handleItemClick} />
      <Menu.Item
        name="subscription"
        active={activeItem === 'subscription'}
        onClick={handleItemClick}
      />
      <Menu.Item name="payments" active={activeItem === 'payments'} onClick={handleItemClick} />
    </Menu>
  );
}

export default MenuClientView;
