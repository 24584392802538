import React from 'react';
import { Segment } from 'semantic-ui-react';

export default function StatusView(props) {
  const { showError, showGlobalRemark, showGlobalRemark2 } = props;
  return (
    <Segment>
      <h1>Status</h1>
      {showError()}
      {showGlobalRemark()}
      {showGlobalRemark2()}
    </Segment>
  );
}
