import React from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { Form, Input } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import Payment from './Payment';
import Container from './Container';

export default function PaymentsView({ client, submitPayment, deletePayment }) {
  return (
    <Container className="small">
      <br />
      <FinalForm
        onSubmit={submitPayment}
        render={({ handleSubmit }) => (
          <Form method="post" onSubmit={handleSubmit}>
            <Form.Group widths="equal">
              <Field
                component={Form.Field}
                control={Input}
                id="payment.form.payDate"
                label="Date"
                name="payDate"
                placeholder="YYYY-MM-DD"
              />
              <Field
                component={Form.Field}
                control={Input}
                id="payment.form.amount"
                label="Amount"
                name="amount"
              />
            </Form.Group>
            <Form.Button color="green">
              <FormattedMessage id="edit" defaultMessage="Add payment" />
            </Form.Button>
          </Form>
        )}
      />
      <br />
      <Payment client={client} deletePayment={deletePayment} />
    </Container>
  );
}
