import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { Icon, Segment } from 'semantic-ui-react';

import Molly from '../assets/faces/molly.jpg';
import Matthew from '../assets/faces/matthew.jpg';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const Container = styled.div`
  display: flex;
`;

const Avatar = styled.img`
  width: 250px;
`;

const Name = styled.div`
  font-size: 25px;
  padding-bottom: 15px;
`;

const Info = styled.div`
  padding: 2px;
`;

const Informations = styled.div`
  padding-right: 35px;
  font-size: 16px;
`;

const MiniInfo = styled.div`
  font-size: 14px;
`;

export default function GeneralView({ client }) {
  const img = client.gender === 'male' ? Matthew : Molly;
  const daysCourses = client.courses ? client.courses.map((c) => days[c - 1]) : [];
  const age = moment().diff(moment(client.birthdate), 'years');
  const name = `${_.startCase(client.firstName)} ${_.startCase(client.lastName)}`;

  return (
    <Segment>
      <h1>General</h1>
      <Container>
        <Avatar src={img} />
        <Informations>
          <Name>{name}</Name>
          <Info>{!!age && `${age} years`}</Info>
          <Info>
            Joined the
            {moment(client.createdAt).format('DD-MM-YYYY')}
          </Info>
          <Info>
            Period {moment(client.startDate).format('DD-MM-YYYY')} -{' '}
            {moment(client.endDate).format('DD-MM-YYYY')}
          </Info>
          <Info>{_.startCase(client.subcription)} subcription</Info>
        </Informations>
        <MiniInfo>
          <Icon name="bookmark outline" />
          {_.startCase(client.courseType)}
          <Info>
            <Icon name="time" />
            {daysCourses.join(' - ')}
          </Info>
          <Info>
            <Icon name="shield alternate" /> {_.startCase(client.label)}
          </Info>
          <Info>
            <Icon name="mail" />
            {client.email}
          </Info>
          <Info>
            <Icon name="phone" />
            {client.phone}
          </Info>
        </MiniInfo>
      </Container>
    </Segment>
  );
}
