const basic = {
  fontFamily: 'Saira Extra Condensed',
  fontWeight: 300,
  lineHeight: 2,
  fontsize: 20,
  letterSpacing: 0.01,
  titleSize: 40,
  error: '#FF0033'
};

const light = {
  name: 'light',
  bodyColor: '#FFFFFF',
  textColor: '#000000',
  primaryColor: '#FD4B08',
  secondaryColor: '#E2DA69',
  disableColor: '#F4F3F9',
  ...basic
};

export default light;
