import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

export const Container = styled.div`
  color: ${(props) => props.theme.textColor};
  font-family: ${(props) => props.theme.fontFamily}, sans-serif;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  width: 100%;
  justify-content: space-between;
`;

export const Logout = styled(Icon)`
  width: 30px;
  padding-right: 10px;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;
