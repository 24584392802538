import React, { useEffect, useRef, useState } from 'react';

const urlParamsToObject = (urlParams) => {
  const obj = {};

  // eslint-disable-next-line
  for (const key of urlParams.keys()) {
    obj[key] = urlParams.get(key);
  }

  return obj;
};

const objToUrlParams = (obj) => {
  const newSearch = new URLSearchParams({});

  Object.keys(obj).forEach((searchField) => {
    newSearch.append(searchField, obj[searchField]);
  });

  return newSearch;
};

function SearchManagment({ search, history, location }) {
  const searchRef = useRef(search.toString());

  useEffect(() => {
    if (search.toString() !== searchRef.current) {
      searchRef.current = search.toString();
      history({
        ...location,
        search: search.toString()
      });
    }
  }, [search, history, location]);

  return null;
}

function ComposeSearchForm(props) {
  const { location, history, component } = props;
  const [search, setSearch] = useState(new URLSearchParams(location.search));
  const Component = component;
  return (
    <div>
      <SearchManagment history={history} search={search} />
      <Component
        {...props}
        initialValues={urlParamsToObject(search)}
        onSubmit={(form) => setSearch(objToUrlParams(form))}
      />
    </div>
  );
}

export default ComposeSearchForm;
