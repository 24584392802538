import React, { useState } from 'react';
import { Button, Icon, Modal, Segment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

function DeleteButton({ onClick, id, modalTitle }) {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Modal
      basic
      open={open}
      onOpen={openModal}
      onClose={closeModal}
      size="tiny"
      trigger={
        <Segment as={Button} icon="trash" fluid basic onClick={(e) => e.stopPropagation()} />
      }
    >
      <Modal.Header>{modalTitle}</Modal.Header>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={closeModal}>
          <Icon name="remove" />
          <FormattedMessage id="no" defaultMessage="No" />
        </Button>
        <Button
          color="green"
          inverted
          onClick={() => {
            onClick(id);
            closeModal();
          }}
        >
          <Icon name="checkmark" />
          <FormattedMessage id="yes" defaultMessage="Yes" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DeleteButton;
