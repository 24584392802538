import React from 'react';
import { Form } from 'semantic-ui-react';

import Error from './Error';

function DropdownMulti({ input, disabled, meta, id, label, options, type }) {
  return (
    <div className="field">
      <Form.Dropdown
        {...input}
        value={input.value || []}
        disabled={disabled}
        error={meta.submitFailed && !!meta.error}
        fluid
        multiple
        search
        selection
        id={id}
        label={<label htmlFor={id}>{label}</label>}
        onChange={(e, data) => input.onChange(data.value)}
        options={[{ text: '', value: '' }].concat(options)}
        type={type}
      />
      <Error submitFailed={meta.submitFailed} error={meta.error} />
    </div>
  );
}

export default DropdownMulti;
