import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { Container, Logout } from './StatusBar.style';
import { logout } from '../../services/authService';

function StatusBar() {
  const history = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/')[1] || 'clients';
  const [activeItem, setActiveItem] = useState(path);

  const handleItemClick = (e, v) => {
    setActiveItem(v.name);
    history(`/${v.name}`);
  };

  return (
    <Container>
      <Menu pointing secondary>
        <Menu.Item name="clients" active={activeItem === 'clients'} onClick={handleItemClick} />
        <Menu.Item name="reports" active={activeItem === 'reports'} onClick={handleItemClick} />
        <Menu.Item
          name="changeLogs"
          active={activeItem === 'changeLogs'}
          onClick={handleItemClick}
        />
      </Menu>
      <Logout name="sign-out" size="large" onClick={() => logout(history)} />
    </Container>
  );
}

export default StatusBar;
