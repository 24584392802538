import { request, handleResponse } from '../helpers/request';
import { authHeader } from '../helpers/auth-header';

function getAllClients(queryParams) {
  return request(`clients${queryParams}`, 'GET', null, authHeader()).then(handleResponse);
}

function getClient(id) {
  return request(`clients/${id}`, 'GET', null, authHeader()).then(handleResponse);
}

function deleteClient(id) {
  return request(`clients/${id}`, 'DELETE', null, authHeader()).then(handleResponse);
}

function addClient(client) {
  let headers = authHeader();
  headers = {
    ...headers,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  return request('clients', 'POST', JSON.stringify(client), headers).then(handleResponse);
}

function updateClient(id, payload) {
  let headers = authHeader();
  headers = {
    ...headers,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  return request(`clients/${id}`, 'PUT', JSON.stringify(payload), headers).then(handleResponse);
}

function addPayment(id, payment) {
  let headers = authHeader();
  headers = {
    ...headers,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  return request(`clients/${id}/payment`, 'POST', JSON.stringify(payment), headers).then(
    handleResponse
  );
}

function removePayment(id, paymentId) {
  let headers = authHeader();
  headers = {
    ...headers,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  return request(`clients/${id}/payment/${paymentId}`, 'DELETE', null, headers).then(
    handleResponse
  );
}

export {
  removePayment,
  getAllClients,
  deleteClient,
  addClient,
  getClient,
  updateClient,
  addPayment
};
