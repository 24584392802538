import React, { PureComponent } from 'react';
import { Button } from 'semantic-ui-react';
import { Label } from '.';

export default class ClientButton extends PureComponent {
  render() {
    return (
      <Label color="teal" horizontal>
        <Button icon onClick={this.props.onClick}>
          {this.props.id}
        </Button>
      </Label>
    );
  }
}
