import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Header, Comment, Label } from 'semantic-ui-react';
import DeleteButton from './DeleteButton';

export default function Payment({ client, deletePayment }) {
  let total = client.payments.reduce((r, n) => r + (n.amount || 0), 0);
  if (client.annualPayment) {
    total = client.annualPayment - total;
  }
  return (
    <Comment.Group minimal>
      <Header as="h3">Payments</Header>
      {client.payments &&
        client.payments.map((r, i) => (
          <div
            key={i}
            style={{
              alignItems: 'baseline',
              display: 'inline-flex',
              justifyContent: 'space-between',
              width: '50%'
            }}>
            <div>{r.payDate}</div>
            <div>{r.amount} €</div>
            <DeleteButton
              id={r.id}
              onClick={() => deletePayment(r.id)}
              modalTitle={
                <FormattedMessage
                  id="deletePayment"
                  defaultMessage="Are you sure you want to delete this payment {paymentId}"
                  values={{
                    paymentId: r.id
                  }}
                />
              }
            />
          </div>
        ))}
      <br />
      <br />
      {client.annualPayment && (
        <div>
          <Label as="a" color="orange" horizontal>
            Annual Payment
          </Label>
          <Label as="a" color="green" horizontal>
            {client.annualPayment} €
          </Label>
          <br />
          <br />
        </div>
      )}
      <div>
        <Label as="a" color="purple" horizontal>
          Total to pay
        </Label>
        <Label as="a" color="green" horizontal>
          {total} €
        </Label>
      </div>
    </Comment.Group>
  );
}
